<template>
    <transition name="el-fade-in-linear">
        <div v-if="refresh" :class="['xctrl-wrap', ctrl.expand?'': 'wrap-height']">
            <div class="ctrl-left">
                <el-scrollbar :class="[ctrl.disableTxt? '':'display-txt', ctrl.align ]" style="width:100%;" ref="leftBox">
                    <template>
                        <div class="ctrl-view">
                            <slot :ctrl="ctrl"></slot>
                        </div>
                    </template>
                </el-scrollbar>
            </div>
            <div class="ctrl-right">
                <el-dropdown @command="setMenuStyle">
                    <el-link :underline="false">
                        <i class="el-icon-d-arrow-left font22 right-label"></i>
                    </el-link>
                    <el-dropdown-menu slot="dropdown" class="ctrl-dwn">
                        <el-dropdown-item class="iconfont" icon="icon-check-txt" :command="'disableTxt'">{{disableTxtLable}}</el-dropdown-item>
                        <el-dropdown-item class="iconfont" :icon="ctrl.expand?'icon-inpand':'icon-expand'" :command="'expand'">{{expandTxt}}</el-dropdown-item>
                        <el-dropdown-item class="iconfont" icon="icon-zuoduiqi" command="align-left">{{msg('左对齐')}}</el-dropdown-item>
                        <el-dropdown-item class="iconfont" icon="icon-youduiqi" command="align-right">{{msg('右对齐')}}</el-dropdown-item>
                        <el-dropdown-item class="iconfont" icon="icon-zhongbuduiqi" command="align-center">{{msg('居中')}}</el-dropdown-item>
                        <el-dropdown-item class="iconfont" icon="icon-jurassic_HorLeft-fenbu" command="align-between">{{msg('水平分布')}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> 
                
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'CtrlBox',
    data(){
        return{
            ctrl: {
                disableTxt: true,
                expand: true,
                align: 'align-left',
                data: this.data,
            },
            disableTxtLable: null, 
            expandTxt: null,

            refresh: true,
        } 
    }, 
    props:{
        data: Object
    },
    mounted(){
        try{
            var ctrlbox  = JSON.parse(localStorage.getItem("ctrlbox"));
            if(ctrlbox!= null) {
                this.ctrl = ctrlbox;
                this.disableTxtLable = this.ctrl.disableTxt? this.msg("隐藏文本") : this.msg("显示文本");
                this.expandTxt = this.ctrl.expand? this.msg("收缩") : this.msg("展开")
            }else{
                this.ctrl.disableTxt = this.msg("隐藏文本");
                this.expandTxt = this.msg("收缩");
            }
        }catch(err){
            console.error(err);
        }

        setTimeout(()=>{
            this.$nextTick(() => {
                try{
                    var leftBox = this.$refs.leftBox;
                    leftBox.$el.addEventListener('onwheel', this.bindHScrollEvent,  false); 
                }catch(err){
                    console.error(err);
                }
            });
        }, 2000)

        
    },
    methods:{
        bindHScrollEvent(event){
            var leftBox = this.$refs.leftBox;
            var wrap = leftBox.$refs['wrap'];
            
            //禁止事件默认行为（此处禁止鼠标滚轮行为关联到"屏幕滚动条上下移动"行为） 
            event.preventDefault();  

            if(leftBox.sizeWidth){
                //设置鼠标滚轮滚动时屏幕滚动条的移动步长  
                var step = 50;  
                if(event.deltaY < 0){  
                    //向上滚动鼠标滚轮，屏幕滚动条左移  
                    wrap.scrollLeft -= step;  
                } else { 
                    //向下滚动鼠标滚轮，屏幕滚动条右移  
                    wrap.scrollLeft += step;  
                } 
            }
        },
        setMenuStyle(command){
            if(command== 'disableTxt'){
                this.ctrl.disableTxt = !this.ctrl.disableTxt;
                this.disableTxtLable = this.ctrl.disableTxt? this.msg("隐藏文本") : this.msg("显示文本")
            }else if(command == 'expand'){
                this.ctrl.expand = !this.ctrl.expand;
                this.expandTxt = this.ctrl.expand? this.msg("收缩") : this.msg("展开")
            }else if(command.indexOf("align") > -1){
                this.ctrl.align = command;
            }

            localStorage.setItem("ctrlbox", JSON.stringify(this.ctrl));
            this.refresh = false;
            setTimeout(() => {
                this.refresh=true;
            }, 100);
        }

    }
}
</script>

<style lang="scss">
.right-label{
transform: rotate(-90deg);}

.xctrl-wrap{
    display:flex; 
    align-items: center;
    flex-wrap: nowrap;
    background: white;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0px 4px 5px rgba(0,0,0,0.05); 
}

.ctrl-right{flex:0 0 30px;
    align-items: center;
    margin-left: 5px;
    box-sizing: border-box;
    margin-top: 5px;
}

.ctrl-left{flex: 0 0 calc(100% - 40px); padding-left: 15px; box-sizing: border-box;  overflow: hidden;}
.ctrl-view{display:flex; }  

.ctrl-left .display-txt .txt{display:none;}
.ctrl-left .txt{font-size:13px; white-space: nowrap;     
    text-align: center;  line-height: 120%;}

.wrap{display:inline-flex; flex-direction: column; align-items: center;}
.wrap .txt{margin-top: -3px;}


.wrap-height{/*height:30px;overflow: hidden; */align-items: flex-start;}
.wrap-height .wrap, .wrap-height .inline{flex-direction: row; align-items: center;}
.wrap-height .wrap .txt{margin-top:0px !important ; margin-left:4px;}

.align-center .ctrl-view{justify-content: center;}
.align-left .ctrl-view{justify-content: flex-start;}
.align-right .ctrl-view{justify-content: flex-end;}
.align-between .ctrl-view{justify-content: space-between;}

.ctrl-dwn i{font-style: normal;}

</style>