<!-- 客诉件核算-->
<template>
<div class="content-panel">
    <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
        <el-col :span="20">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{msg('客诉件核算')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
    </el-row>
    <el-form ref="form" label-width="100px" style="margin-top:20px" @submit.native.prevent>
        <el-row>
            <el-col :xs="24" :sm="14">
                <div style="text-align:left;margin-top: 5px;margin-left: 20px;">
                    <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-circle-check"></i><span>{{msg('核算')}}</span></el-button>
                    <el-button type="primary" size="mini"  @click="doExport()"><i class="el-icon-download"></i><span>{{msg('导出')}}</span></el-button>
                </div>
            </el-col>
        </el-row>
    </el-form>
    <div class="table-wrap">
        <div style="margin-left:20px; font-size:14px;">{{info}}</div>
        <el-progress :percentage="speed"></el-progress>
        <vxe-table
          ref="xTable"
          border  export-config size="mini"
          :expand-config="{iconOpen: 'fa el-icon-remove', iconClose: 'fa el-icon-circle-plus'}"
          :data="tableData">
            <vxe-table-column type="seq" width="60"></vxe-table-column>
            <vxe-table-column field="code" width="125" :title="msg('单号')" ></vxe-table-column>
            <vxe-table-column field="lipeiCode" width="155" :title="msg('客诉件流水号')"></vxe-table-column>
            <vxe-table-column field="fullname" width="100" :title="msg('归属人姓名')"></vxe-table-column>
            <vxe-table-column field="companyName" width="100" :title="msg('归属公司')" ></vxe-table-column>
            <vxe-table-column field="rate" width="80" :title="msg('汇率')"></vxe-table-column>
            <vxe-table-column field="amountTai" width="150" :title="msg('客诉件金额(台币)')" :formatter="formatterAmount">
                <template v-slot="{ row }">
                    {{(row.amountTai/100.0).toFixed(2)}}
                </template>
            </vxe-table-column>
            <vxe-table-column field="amountCny" width="150" :title="msg('客诉件金额(人民币)')" :formatter="formatterAmount">
                <template v-slot="{ row }">
                    {{(row.amountCny/100.0).toFixed(2)}}
                </template>
            </vxe-table-column>
            <vxe-table-column field="msg" :title="msg('计算结果')"></vxe-table-column>
        </vxe-table>
    </div>
</div>
</template>

<script>
import axios from "axios";
export default {
    name: "FinanceLipeiService",
    components: {
    },
    mounted(){
        this.init();
    },
    data(){
        return {
            tableData: [
            ],
            ids:null,
            rate:0,
            speed:0,
            timer:null,
            len:0,
            info:null,
        }
    },
    computed: {
        isMinScreen: { //是否小屏幕，浏览器
            get() {
                return this.$store.state.isMinScreen;
            }
        },
        docHeight: { //表格的高度
            get() {
                var height = this.$store.state.docHeight;
                return height - 165;
            }
        }
    },
    methods:{
        init(){
            this.ids = window.localStorage.getItem("kesuStockIds");
            this.len = this.$kit.getQueryValue("len");
        },
        getData(){
            let param = {
                ids:this.ids,
            }
            this.$axios.post(this.$kit.api.finance.computeKesu,param)
                .then((result) => {
                    this.$message(result.msg);
                    if(result.status){
                        this.speed = 0;
                        this.tableData = [];
                        this.info = this.msg('计算中');
                        this.starTimer();
                    }
                });
        },
        starTimer(){
            this.timer = setInterval(()=>{
                if(this.speed < 100){
                    this.getPercent();
                }else{
                    this.finish();
                    clearInterval(this.timer);
                }
            }
            ,1000)
        },
        onSubmit(){
            // if(!this.rate){
            //     this.$message(this.msg('请输入汇率'));
            //     return;
            // }
            this.getData();
        },
        getPercent(){
            axios.request({
                url: this.$kit.api.finance.kesuPercent, 
                method: 'get',
                headers: {"Content-Type":"application/json; charset=utf-8",
                        "X-Requested-With":"XMLHttpRequest",
                    },
                withCredentials: true,
            }).then((response) => {

                    let result = response.data;
                    if(result.status){
                        if(result.data && result.data.length > 0){
                            // result.data.forEach(e => {
                            //     this.tableData.push(e);
                            // });
                            this.tableData = result.data;
                            this.speed = (this.tableData.length/this.len)*100;
                            this.info = this.msg('当前进度')+this.tableData.length+"/"+this.len;
                        }

                    }
                });
        },
        getLabel(key, value){
            if(value == null) return "";
            let data = this.dicts[key].find(e => e.value == value);
            return data&&data.label? data.label : value;
        },
        finish(){
            axios.request({
                url: this.$kit.api.finance.kesuComputeFinish, 
                method: 'get',
                headers: {"Content-Type":"application/json; charset=utf-8",
                        "X-Requested-With":"XMLHttpRequest",
                    },
                withCredentials: true,
            })
        },
        doExport(){
            this.$refs.xTable.openExport({ isPrint: false })
        },
        formatterAmount({ cellValue }){
            return (cellValue/100.0).toFixed(2)
        }, 
        formatCode(data){
            return data.row.code;
        }
    },
    destroyed(){
        if(this.timer){
            clearInterval(this.timer);
        }
    }
}
</script>

<style lang="scss" scoped>

.table-wrap{
    width:100%;
    overflow:auto;
}
.xtitle{font-size:16px;}
</style>
