<template>
    <el-pagination id="xpagination" ref="pagenator" :class="[isMinScreen?'min-page':'']"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :background="true"
        :current-page.sync="query.pageIndex"
        :page-sizes="!hasPermission('stock:pageSizes')?[10,20 ,50, 100, 200, 500, 1000]:[10,20 ,50, 100, 200, 500, 1000, 2000, 5000, 10000]"
        :page-size="query.length"
        layout="total, sizes, prev, pager, next, jumper"
        :total="query.pageTotal">
    </el-pagination>
</template>

<script>
export default {
    name: 'Pagination',
    data(){
        return{
            prevPrevScreen: null,
            pageSizes:[],
        } 
    }, 
    props:{
        query:Object
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                if(is !== this.prevPrevScreen){
                    this.resizePagenator(is);
                }

                this.setPrevPrevScreen(is);
                return is;
            }
        },
    },
    methods:{
        setPrevPrevScreen(is){
            this.prevPrevScreen = is;
        },
        resizePagenator(isMinScreen){
            this.$nextTick(()=>{
                var pagenator = this.$refs.pagenator;
                var pagenatorDom = pagenator.$el;
                if(isMinScreen){
                    
                    var prev = pagenatorDom.querySelector("button.btn-prev");    //button.btn-prev         
                    pagenatorDom.insertBefore(document.createElement("br"), prev);
                    var jumper = pagenatorDom.querySelector(".el-pagination__jump"); //el-pagination__jump
                    pagenatorDom.insertBefore(document.createElement("br"), jumper );
                
                }else{
                    var br = pagenatorDom.querySelectorAll("br");
                    if(br){
                        for(var i = 0; i<br.length; i++){
                            br[i].remove();
                        }
                    }
                }
            });
             
        },

        handleSizeChange(val) {  //分页的每页数据变化后的回调事件
            //console.log(`每页 ${val} 条`);
           this.handlerChange(val, 0);
        },
        handleCurrentChange(val) {   //分页的当前页改变后的回调事件
            //console.log(`当前页: ${val}`);
            var start = this.query.length * (val -1);
            this.handlerChange(this.query.length, start);
        },

        handlerChange(length, start){
            this.$emit("page-change", [length, start]);
        }

    }
}
</script>

<style lang="scss">

#xpagination.min-page .btn-prev{margin-bottom:10px; margin-top:10px;}
#xpagination.min-page .el-pager, #xpagination.min-page .btn-next{margin-top:10px;}

</style>