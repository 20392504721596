<!--编辑贴单费-->
<template>
    <div class="ctrl-box">
        <h4 style="text-align:center;">{{msg('贴单费设置')}}</h4>
        <el-row>
            <el-col :xs="24" :sm="12" :md="8">
                <el-form-item :label="msg('报价')" label-width="100px">
                    <el-input type='number' v-model="pasteBill.price" size="mini" clearable></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
                <el-form-item :label="msg('单位')" label-width="100px">
                    <el-select v-model="pasteBill.unit" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.units" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" label-width="100px">
                <el-form-item :label="msg('币种')">
                    <el-select v-model="pasteBill.currency" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.currencies" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'OfferEditPasteBill',
    props:{
        pasteBill:Object
    },
    
}
</script>

<style scoped lang="scss">


</style>
