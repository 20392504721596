<!--编辑报价单-->
<template>
<el-form ref="form" :model="offer" label-width="80px" size="mini" >
    <el-row>
        <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="msg('客户名')">
                <el-autocomplete
                    v-model="offer.fullname"
                    :fetch-suggestions="searchUser"
                    :placeholder="msg('请输入账号或者姓名')"
                    @clear="clearUsername"
                    @select="selectUser"
                    :disabled="offer.id?true:false"
                ></el-autocomplete>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="msg('客户账号')">
                <el-input v-model="offer.username" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="msg('角色')">
                <el-input v-model="offer.roleLabel" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="msg('报价设置名')" label-width="120px">
                <el-input v-model="offer.name"></el-input>
            </el-form-item>
        </el-col>
        <!-- <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="msg('是否默认')" label-width="120px">
                <el-checkbox v-model="offer.defaulted" :true-label="1" :false-label="0"></el-checkbox>
            </el-form-item>
        </el-col> -->
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="msg('报价备注1')" label-width="120px">
                <el-input v-model="offer.remark1"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8">
            <el-form-item :label="msg('报价备注2')" label-width="120px">
                <el-input v-model="offer.remark2"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    
    <edit-transit :datas="offer.transits"/>
    <edit-delivery :datas="offer.deliveries"/>
    <edit-pkg :datas="offer.pkgs"/>
    <edit-paste-bill :paste-bill="offer.pasteBill"/>
    <edit-proxy :datas="offer.proxies"/>

    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>

import OfferEditTransit from './OfferEditTransit.vue';
import OfferEditDelivery from './OfferEditDelivery.vue';
import OfferEditPkg from './OfferEditPkg.vue';
import OfferEditPasteBill from './OfferEditPasteBill.vue';
import OfferEditProxy from './OfferEditProxy.vue';

export default {
    name: 'OfferEdit',
    props:{
        offer:Object
    },
    components: {
        EditTransit: OfferEditTransit,
        EditDelivery:OfferEditDelivery,
        EditPkg:OfferEditPkg,
        EditPasteBill:OfferEditPasteBill,
        EditProxy:OfferEditProxy,
    },
    methods:{
        searchUser(queryString, cbFn){  //搜索用户
            this.$axios.get(this.$kit.api.user.search+"?name="+encodeURIComponent(queryString)+'&type=1&simple=1')
                .then((result) => {
                    if(result.status){
                        let users =[];
                        result.data.forEach(e => {
                            users.push({
                                id: e.id,
                                value: e.value,
                            });
                        });
                        cbFn(users);
                    }
                });
        },
        //清除选中的用户
        clearUsername(){
            this.offfer.username = null;
            this.offfer.userId = null;
            this.offfer.roleLabel = null;
            this.offfer.fullename = null;
        },
        //选中用户
        selectUser(item) { //获取选中的用户
            this.offer.userId = item.id;
            /*eslint-disable-next-line*/
            let temps = item.value.split(/[\/\(\)]/);

            this.offer.fullname = temps[1];
            this.offer.username = temps[2];
            this.offer.roleLabel = temps[3];
        },
        //提交
        onSubmit(){

            if(!this.offer.userId){
                this.$message(this.msg("请选择用户"));
                return ;
            }

            var url = null;
            if(this.offer.id==null){
                url = this.$kit.api.offer.create;
            }else{
                url = this.$kit.api.offer.edit;
            }

            //这里转换数据
            let params = JSON.parse(JSON.stringify(this.offer));
            params.pasteBill.price = (params.pasteBill.price*100).toFixed(); 
            params.transits.forEach(t => t.price = (t.price*100).toFixed());
            params.deliveries.forEach(t => t.price = (t.price*100).toFixed());
            params.pkgs.forEach(t => t.price = (t.price*100).toFixed());
            params.proxies.forEach(t => {
                t.price = (t.price*100).toFixed();
                t.proxyMin = (t.proxyMin*100).toFixed();
                t.proxyMax = (t.proxyMax*100).toFixed();
            });

            this.$axios.post(url, params)
                .then((result)=>{
                    if(result.status){
                        this.$message(result.msg || (this.id==null? this.msg('create-ok'):this.msg('update-ok')));
                        if(result.status){
                            this.$emit("close-dg", true);
                        }
                    }else{
                        this.$message(result.msg || (this.id==null? this.msg('create-err'):this.msg('update-err')));
                    }
                
                });
        }
  }
}
</script>

<style scoped lang="scss">


</style>
