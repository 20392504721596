<!--报价设置详情-->
<template>
    <div class="ctrl-wrap" style="padding:30px;">
        <h2 class="xtitle">{{msg("报价信息")}}</h2>
        <el-row>
            <el-col :span="8">
                <span class="xlabel">{{msg('客户名')}}：</span><span>{{offer.fullname}}</span>                            
            </el-col>
            <el-col :span="8">
                <span class="xlabel">{{msg('客户账号')}}：</span><span>{{offer.username}}</span>                            
            </el-col>
            <el-col :span="8">
                <span class="xlabel">{{msg('角色')}}：</span><span>{{offer.roleLabel}}</span>                            
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <span class="xlabel">{{msg('报价设置名')}}：</span><span>{{offer.name}}</span>                            
            </el-col>
            <el-col :span="8" v-if="offer.remark1">
                <span class="xlabel">{{msg('报价备注1')}}：</span><span>{{offer.remark1}}</span>                            
            </el-col>
            <el-col :span="8" v-if="offer.remark2">
                <span class="xlabel">{{msg('报价备注2')}}：</span><span>{{offer.remark2}}</span>                            
            </el-col>
        </el-row>

        <h2 class="xtitle">{{msg("运费")}}</h2>
        <table class="xtable">
            <thead>
                <tr>
                    <th>{{msg('收费方式')}}</th>
                    <th>{{msg('计重策略')}}</th>
                    <th>{{msg('报价')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="t in offer.transits" :key="t.id">
                    <td>{{getFeeType(t.type)}}</td>
                    <td>{{getStrategy(t.strategy)}}</td>
                    <td>{{t.price}} {{getUnit(t.unit)}} {{getCurrency(t.currency)}}</td>
                </tr>
            </tbody>
        </table>

        <h2 class="xtitle">{{msg("交派费")}}</h2>
        <table class="xtable">
            <thead>
                <tr>
                    <th>{{msg('来源')}}</th>
                    <th>{{msg('配送方式')}}</th>
                    <th>{{msg('卖场')}}</th>
                    <th>{{msg('报价')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="t in offer.deliveries" :key="t.id">
                    <td>{{getOrigin(t.origin)}}</td>
                    <td>{{getExpcompany(t.type)}}</td>
                    <td>{{getPlatforms(t.platform)}}</td>
                    <td>{{t.price}} {{getUnit(t.unit)}} {{getCurrency(t.currency)}}</td>
                </tr>
            </tbody>
        </table>

        <h2 class="xtitle">{{msg("打包费")}}</h2>
        <table class="xtable">
            <thead>
                <tr>
                    <th>{{msg('打包类型')}}</th>
                    <th>{{msg('商品总类数')}}</th>
                    <th>{{msg('总数量')}}</th>
                    <th>{{msg('报价')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="t in offer.pkgs" :key="t.id">
                    <td>{{getPkgType(t.type)}}</td>
                    <td>{{t.productTypeQtyMin}} - {{t.productTypeQtyMax}}</td>
                    <td>{{t.productQtyMin}} - {{t.productQtyMax}}</td>
                    <td>{{t.price}} {{getUnit(t.unit)}} {{getCurrency(t.currency)}}</td>
                </tr>
            </tbody>
        </table>

        <h2 v-if="offer.pasteBill" class="xtitle">{{msg("贴单费")}}</h2>
        <el-row v-if="offer.pasteBill">
            <el-col :span="8">
                <span class="xlabel">{{msg('报价')}}：</span>
                <span>
                    {{offer.pasteBill.price}} 
                    {{getUnit(offer.pasteBill.unit)}} 
                    {{getCurrency(offer.pasteBill.currency)}}
                </span>                            
            </el-col>
        </el-row>


        <h2 class="xtitle">{{msg("代收手续费")}}</h2>
        <table class="xtable">
            <thead>
                <tr>
                    <th>{{msg('配送方式')}}</th>
                    <th>{{msg('代收金额')}}</th>
                    <th>{{msg('报价')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="t in offer.proxies" :key="t.id">
                    <td>{{getExpcompany(t.type)}}</td>
                    <td>{{t.proxyMin}} - {{t.proxyMax}}</td>
                    <td>{{t.price}} {{getUnit(t.unit)}} {{getCurrency(t.currency)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>


export default {
    name: 'OfferDetail',
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    props: {
        offer: Object
    },
    methods: {
        getOrigin(origin){
            let item = this.dicts.orderOrigin.find(e => e.value == origin);
            return item?item.label : origin;
        },
        getExpcompany(company){
            let item = this.dicts.expCompany.find(e => e.value == company);
            return item?item.label : company;
        },
        getPlatforms(val){
            let item = this.dicts.orderPlatforms.find(e => e.value == val);
            return item?item.label : val;
        },
        getPkgType(type){
            let item = this.dicts.stockExpPkgType.find(e => e.value == type);
            return item?item.label : type;
        },
        getFeeType(type){
            let t = this.dicts.feeTypes[type];
            return t?t.label: type;
        },
        getStrategy(s){
            let t = this.dicts.weightStraties[s];
            return t?t.label: s;
        },
        getUnit(s){
            let t = this.dicts.units[s];
            return t?t.label: s;
        },
        getCurrency(s){
            let t = this.dicts.currencies[s];
            return t?t.label: s;
        }
    }
    
}

</script>
<style scope='scss'>
.xtitle{font-size:16px;}
.xlabel{color:  #606266}
.xtext{color: #303133}
table.xtable{border-collapse: collapse; margin: 0 auto; text-align: center; width:100%;}
.xtable tr{border-top: 1px solid #c0c6d4; border-left: 1px solid #c0c6d4; }
.xtable td, .xtable th{border-right: 1px solid #c0c6d4; border-bottom: 1px solid #c0c6d4; padding: 3px 10px;}
</style>