<template>
    <div class="query-wrap">
        <el-row style="text-align:right;">
            <el-checkbox style="margin-right:15px;" v-if="query.hasOwnProperty('history')" v-model="query.history" :true-label="1" :false-label="0">{{msg('查询历史纪录')}}</el-checkbox>
            <el-radio-group v-model="querySetting.size" size="mini">
                <el-radio-button label="mini">小</el-radio-button>
                <el-radio-button label="small">中</el-radio-button>
                <el-radio-button label="medium">大</el-radio-button>
            </el-radio-group>
            <el-radio-group v-model="querySetting.inline" size="mini">
                <el-radio-button :label="true">单行</el-radio-button>
                <el-radio-button :label="false">多行</el-radio-button>
            </el-radio-group>
        </el-row>
        <el-form ref="form" :model="query" :inline="querySetting.inline" label-position="right" @submit.native.prevent
                :label-width="labelWidth? labelWidth : '80px'" :size="querySetting.size">
            <slot v-bind:query="query"></slot>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'QueryBox',
    data(){
        return{
           querySetting: {
                labelPosition: "right",
                inline: true,
                size: 'mini'
           }
        } 
    }, 
    mounted(){
        try{
            var setting  = JSON.parse(localStorage.getItem("querySetting"));
            if(setting!= null) {
                this.querySetting = setting;
            }
        }catch(err){
            console.error(err);
        }
    },
    props:{
        query: Object,
        labelWidth: String
    },
    methods:{
        

    }
}
</script>

<style lang="scss">

</style>