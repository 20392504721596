<!--报价设置-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link v-if="hasPermission('offer:detail')" :underline="false" @click="view()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-chakan" font-size="27px;"/>
                            <span class="txt" >{{msg('查看')}}</span>      
                        </div>                
                    </el-link>
                    <el-link v-if="hasPermission('offer:add')" :underline="false" @click="add()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt" >{{msg('添加报价')}}</span>      
                        </div>                
                    </el-link>
                    <el-link v-if="hasPermission('offer:update')" :underline="false" @click="edit()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt" >{{msg('编辑报价')}}</span>      
                        </div>                
                    </el-link>
                    <el-link v-if="hasPermission('offer:copyOf')" :underline="false" @click="copyOf()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-fuzhi" font-size="27px;"/>
                            <span class="txt" >{{msg('复制报价')}}</span>      
                        </div>                
                    </el-link>
                    <el-link v-if="hasPermission('offer:usedOffer')"  :underline="false" @click="editUsed(1)">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-jinyong" font-size="27px;"/>
                            <span class="txt" >{{msg('调用')}}</span>      
                        </div>
                    </el-link>
                    <el-link v-if="hasPermission('offer:usedOffer')"  :underline="false" @click="editUsed(0)">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-jiechusuoding" font-size="27px;"/>
                            <span class="txt" >{{msg('取消调用')}}</span>      
                        </div>
                    </el-link>
                    <el-link v-if="hasPermission('offer:delete')"  :underline="false" @click="deletes()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt" >{{msg('删除')}}</span>      
                        </div>                
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link v-if="hasPermission('offer:remark')"  :underline="false" @click="openRemark">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt" >{{msg('更新报价备注')}}</span>      
                        </div>
                    </el-link>
                    <el-link v-if="hasPermission('offer:resetTransit')" type="primary" :underline="false" @click="resetFee(1)">
                        <div class="wrap" >
                            <i class="iconfont icon-price-transit font22" ></i>
                            <span class="txt" >{{msg('运费设置')}}</span>      
                        </div>
                    </el-link>
                    <el-link v-if="hasPermission('offer:resetDelivery')" type="primary" :underline="false" @click="resetFee(2)">
                        <div class="wrap" >
                            <i class="iconfont icon-price-delivery font22" ></i>
                            <span class="txt" >{{msg('交派费设置')}}</span>      
                        </div>
                    </el-link>
                    <el-link v-if="hasPermission('offer:resetPkg')" type="primary" :underline="false" @click="resetFee(3)">
                        <div class="wrap" >
                            <i class="iconfont icon-price-pkg font22" ></i>
                            <span class="txt" >{{msg('打包费设置')}}</span>      
                        </div>
                    </el-link>
                    <el-link v-if="hasPermission('offer:resetPasteBill')" type="primary" :underline="false" @click="resetFee(4)">
                        <div class="wrap" >
                            <i class="iconfont icon-price-praste font22" ></i>
                            <span class="txt" >{{msg('贴单费设置')}}</span>      
                        </div>
                    </el-link>
                    <el-link v-if="hasPermission('offer:resetProxy')" type="primary" :underline="false" @click="resetFee(5)">
                        <div class="wrap" >
                            <i class="iconfont icon-price-proxy font22" ></i>
                            <span class="txt" >{{msg('代收手续费设置')}}</span>      
                        </div>
                    </el-link>
                    <el-link v-if="hasPermission('offer:resetRate')" type="primary" :underline="false" @click="editRate()">
                        <div class="wrap" >
                            <i class="iconfont icon-price-transit font22" ></i>
                            <span class="txt" >{{msg('汇率设置')}}</span>      
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="100px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('姓名 账户')">
                        <!-- <el-input v-model="scope.query.username" :placeholder="msg('账号或者姓名')" clearable></el-input> -->
                        <el-input type="textarea"  :rows="3" v-model="scope.query.username" style="margin-top:0px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="msg('报价名')">
                        <el-input v-model="scope.query.name" :placeholder="msg('请输入报价名')" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('报价备注1')">
                        <el-input v-model="scope.query.remark1" :placeholder="msg('请输入报价备注1')" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('报价备注2')">
                        <el-input v-model="scope.query.remark2" :placeholder="msg('请输入报价备注2')" clearable></el-input>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list(true)" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" size="mini"
                resizable border
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="datas"
                :columns="columns"
            ></vxe-grid>

            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>

        <!--费用设置-->
        <span v-if="resetVisible1">
            <el-dialog
                :title="resetTitle" :fullscreen="isMinScreen"
                :visible.sync="resetVisible"
                :close-on-click-modal="false"
                width="70%">
                    <div>
                        <edit-transit v-if="resetType==1" :datas="fees"/>
                        <edit-delivery v-else-if="resetType==2" :datas="fees"/>
                        <edit-pkg v-else-if="resetType==3" :datas="fees"/>
                        <el-form v-else-if="resetType==4" ref="form" :model="fees" label-width="80px" size="mini" >
                            <edit-paste-bill :paste-bill="fees"/>
                        </el-form>
                        <edit-proxy v-else-if="resetType==5" :datas="fees"/>
                        <div style="text-align:center; margin-top:15px; margin-bottom:20px;">
                            <el-button type="primary" size="mini"  @click="updateReset"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
                            <el-button  size="mini" v-on:click="closeReset">{{msg('取消')}}</el-button>
                        </div>
                    </div>
            </el-dialog>
        </span>

        <span v-if="editVisible1">
            <el-dialog
                :title="editTitle" :fullscreen="isMinScreen"
                :visible.sync="editVisible"
                :close-on-click-modal="false"
                width="70%">
                    <edit @close-dg="closeEdit()" :offer="curr"/>
            </el-dialog>
        </span>

        <span v-if="copyVisible1 && curr">
            <el-dialog 
                :title="msg('复制报价设置')" :fullscreen="isMinScreen"
                :visible.sync="copyVisible"
                :close-on-click-modal="false"
                width="400">
                    <copy @close-dg="closeCopy()" :offer-id="curr.id"/>
            </el-dialog>
        </span>

        <el-dialog 
            :title="msg('更新报价备注')" :fullscreen="isMinScreen"
            :visible.sync="remarkVisible"
            :close-on-click-modal="false"
            width="400">
                <div>
                    <el-form ref="remarkForm" :model="fees" label-width="80px" size="mini" >
                        <el-form-item :label="msg('报价备注1')" label-width="120px">
                            <el-input v-model="remarkForm.remark1"></el-input>
                        </el-form-item>
                        <el-form-item :label="msg('报价备注2')" label-width="120px">
                            <el-input v-model="remarkForm.remark2"></el-input>
                        </el-form-item>
                    </el-form>
                    <div style="text-align:center; margin-top:15px; margin-bottom:20px;">
                        <el-button type="primary" size="mini"  @click="updateRemark"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
                        <el-button  size="mini" v-on:click="closeRemark">{{msg('取消')}}</el-button>
                    </div>
                </div>
        </el-dialog>

        <el-dialog 
            :title="msg('查看')" :fullscreen="isMinScreen"
            :visible.sync="viewVisible"
            :close-on-click-modal="false"
            width="70%">
                <offer-detail :offer="curr"/>
        </el-dialog>

        <el-dialog :title="msg('汇率设置')" :visible.sync="editRateVisible" :fullscreen="isMinScreen"  @opened="openedRate" 
            :close-on-click-modal="false" 
                width="400px">
            <offer-edit-rate ref="editRateDg" v-on:close-dg="closeRateDialog"/>
        </el-dialog>

    </div>
</template>
<script>
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import OfferEdit from "./OfferEdit.vue";
import OfferCopy from "./OfferCopy.vue";

import OfferEditTransit from './OfferEditTransit.vue';
import OfferEditDelivery from './OfferEditDelivery.vue';
import OfferEditPkg from './OfferEditPkg.vue';
import OfferEditPasteBill from './OfferEditPasteBill.vue';
import OfferEditProxy from './OfferEditProxy.vue';
import OfferDetail from './OfferDetail.vue';
import OfferEditRate from './OfferEditRate.vue';

export default {
    name: 'Offer',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
        Edit: OfferEdit,
        Copy: OfferCopy,
        EditTransit: OfferEditTransit,
        EditDelivery:OfferEditDelivery,
        EditPkg:OfferEditPkg,
        EditPasteBill:OfferEditPasteBill,
        EditProxy:OfferEditProxy,
        OfferDetail:OfferDetail,
        OfferEditRate:OfferEditRate,
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    data(){
        return {
            query:{
                username: null,
                name: null,
                remark1: null,
                remark2: null,
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
                
            },
            datas: [],
            showQuery:false,
            editTitle: null,
            editVisible: false,
            editVisible1: true,
            copyVisible: false,
            copyVisible1: true,
            viewVisible: false,
            editRateVisible:false,
            curr: null, //当前选中的对象

            resetVisible: false,
            resetVisible1: true,
            resetTitle: null,
            resetType: 0,   //1-运费设置，2-派件费设置，3-打包费设置，4-贴单费设置， 5-代收手续费设置，
            resetIds: null,
            fees: null, //费用设置,

            remarkVisible: false,
            remarkForm: {
                remark1: null,
                remark2: null
            },

            columns: [
                {type: 'checkbox', minWidth: 50, width:60, fixed:"left" },
                {field: "id", title: this.msg("ID"), minWidth: 50,width:90},
                {field: "username", title: this.msg("账户"),minWidth: 90, sortable: true},
                {field: "fullname", title: this.msg("姓名"),minWidth: 90, sortable: true},
                {field: "roleLabel", title: this.msg("角色"),minWidth: 90, sortable: true},
                {field: "userTag", title: this.msg("用户组别"),minWidth: 110, sortable: true,
                    filters : [{data: {vals: [], sVal: ''}}],
                    filterRender : {name: 'FilterContent'}
                },
                {field: "rate", title: this.msg("汇率"),minWidth: 100, sortable: true},
                {field: "name", title: this.msg("报价名"),minWidth: 100, sortable: true},
                {field: 'offUsed', title: this.msg('调用状态'), minWidth: 100, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    // slots: {
                    //     default: ({ row }) => {
                    //         return [
                    //             <el-tag type={row.used==1?'success':'primary'}>{row.used==1?'使用中':'无'}</el-tag>
                    //         ];
                    //     },
                    // }
                },
                {field: "remark1", title: this.msg("备注1"),minWidth: 100, sortable: true,
                    filters : [{data: {vals: [], sVal: ''}}],
                    filterRender : {name: 'FilterContent'}
                },
                {field: "remark2", title: this.msg("备注2"),minWidth: 100, sortable: true,
                    filters : [{data: {vals: [], sVal: ''}}],
                    filterRender : {name: 'FilterContent'}
                },
                // {field: "defaulted", title: this.msg("是否默认"),minWidth: 90, sortable: true,
                //     slots: {
                //         default: ({ row }) => {
                //             return row.defaulted == 1 ? '是' : '否';
                //         },
                //     }
                // },
            ],
        }
    },
    mounted(){
        this.list();
    },
    methods:{
        list(refresh){

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            if(this.query.username){
                let cs = this.query.username.split(/\s+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                this.query["username"] = css.join(",");
            }else{
                this.query["username"] = '';
            }

            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.offer.list, params)
                .then((result) => {
                    if(result.status){
                        //this.datas = result.data.list;
                        //this.buildData(result.data.list);
                        this.datas = result.data.list;
                        this.datas.forEach(e=>{
                            e.rate = (e.rate/100).toFixed(2)
                        })
                        this.query.pageTotal = result.data.total;
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        buildData(offers){
            let temps = [];

            // offers.forEach(e => {

            //     let offer = temps.find(t => t.id == e.id);
            //     if(!offer){
            //         offer = {
            //             id: e.id,
            //             userId: e.userId,
            //             username: e.username,
            //             fullname: e.fullname,
            //             roleLabel: e.roleLabel,
            //             name: e.name,
            //             nameId: e.id,
            //             remark1: e.remark1,
            //             remark1Id: e.id,
            //             remark2: e.remark2,
            //             remark2Id: e.id,
            //             remark3: e.remark3,
            //             remark3Id: e.id,
            //             defaulted: e.defaulted,
            //             defaultedId: e.id,
            //             transits: [],
            //             deliveries: [],
            //             pkgs: [],
            //             pasteBill: {
            //                 id:e.t4id,
            //                 offerId: e.t4offerid,
            //                 price: (e.t4price/100).toFixed(2),
            //                 unit: e.t4unit,
            //                 currency: e.t4currency
            //             },
            //             proxies: [],
            //         };
            //         temps.push(offer);
            //     }

            //     let index = offer.transits.findIndex(t => t.id === e.t1id);
            //     if(index == -1){
            //         offer.transits.push({
            //             id:e.t1id,
            //             offerId: e.t1offerId,
            //             type: e.t1type,
            //             strategy: e.t1strategy,
            //             price: (e.t1price/100).toFixed(2),
            //             unit: e.t1unit,
            //             currency: e.t1currency,
            //             defaulted: e.t1defaulted
            //         });
            //     }

            //     let index1 = offer.deliveries.findIndex(t => t.id === e.t2id);
            //     if(index1 == -1){
            //         offer.deliveries.push({
            //             id:e.t2id,
            //             offerId: e.t2offerid,
            //             type: e.t2type,
            //             origin: e.t2origin,
            //             platform:e.t2platform,
            //             price: (e.t2price/100).toFixed(2),
            //             unit: e.t2unit,
            //             currency: e.t2currency
            //         });
            //     }

            //     let index2 = offer.pkgs.findIndex(t => t.id === e.t3id);
            //     if(index2 == -1){
            //         offer.pkgs.push({
            //             id:e.t3id,
            //             offerId: e.t3offerid,
            //             productTypeQtyMin: e.t3productTypeQtyMin,
            //             productTypeQtyMax: e.t3productTypeQtyMax,
            //             productQtyMin: e.t3productQtyMin,
            //             productQtyMax: e.t3productQtyMax,
            //             type: e.t3type,
            //             price: (e.t3price/100).toFixed(2),
            //             unit: e.t3unit,
            //             currency: e.t3currency,
            //         });
            //     }
            //     let index3 = offer.proxies.findIndex(t => t.id === e.t5id);
            //     if(index3 == -1){
            //         offer.proxies.push({
            //             id:e.t5id,
            //             offerId: e.t5offerId,
            //             proxyMin: (e.t5proxyMin/100).toFixed(2),
            //             proxyMax: (e.t5proxyMax/100).toFixed(2),
            //             type: e.t5type,
            //             price: (e.t5price/100).toFixed(2),
            //             unit: e.t5unit,
            //             currency: e.t5currency,
            //         });
            //     }

            // });
            //重新生成行数
            let temps1 = [];
            offers.forEach(t => {
                
                t.transits.forEach(e => {
                    e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                });
                t.deliveries.forEach(e => {
                    e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                });
                t.pkgs.forEach(e => {
                    e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                });
                t.proxies.forEach(e => {
                    e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                    e.proxyMin = e.proxyMin!=0?(e.proxyMin/100.0).toFixed(2):0;
                    e.proxyMax = e.proxyMax!=0?(e.proxyMax/100.0).toFixed(2):0;
                });
                t.pasteBill.price = (t.pasteBill.price/100.0).toFixed(2);
                console.log(t.rate);

                let lens = [t.transits.length, t.deliveries.length, t.pkgs.length, t.proxies.length];
                let max = Math.max.apply(null,lens);
                if(max > 0){
                    for(let i=0; i<max; i++){
                        let offer = this.buildRow(t, i);
                        temps1.push(offer);
                    }
                }else{
                    let offer = this.buildRow(t, -1);
                    temps1.push(offer);
                }
            });


            this.datas = temps1;

        },
        buildRow(t, i){ 
            let offer = Object.assign({}, t);
            offer.nameId=offer.id;
            offer.fullnameId = offer.id;
            offer.usernameId = offer.id;
            offer.roleLabelId = offer.id;
            offer.remark1Id= offer.id;
            offer.remark2Id= offer.id;
            offer.remark3Id= offer.id;
            offer.defaultedId= offer.id;

            if(i==0){
                offer.t4currency = offer.pasteBill.currency;
                offer.t4id = offer.pasteBill.id;
                offer.t4offerid = offer.pasteBill.offerId;
                offer.t4price = offer.pasteBill.price;
                offer.t4unit = offer.pasteBill.unit;
            }else{
                offer.t4currency =null;
                offer.t4id = null;
                offer.t4offerid = null;
                offer.t4price =null;
                offer.t4unit = null;
            }

            let transit = t.transits[i];
            if(transit){
                offer.t1id=transit.id;
                offer.t1offerId=transit.offerId;
                offer.t1type=transit.type;
                offer.t1strategy=transit.strategy;
                offer.t1price=transit.price;
                offer.t1unit=transit.unit;
                offer.t1currency=transit.currency;
                offer.t1defaulted=transit.defaulted
            }else{
                offer.t1id=null;
                offer.t1offerId=null;
                offer.t1type=null;
                offer.t1strategy=null;
                offer.t1price=null;
                offer.t1unit=null;
                offer.t1currency=null;
                offer.t1defaulted=null
            }

            let d = t.deliveries[i];
            if(d){
                offer.t2id=d.id;
                offer.t2offerid=d.offerId;
                offer.t2type=d.type;
                offer.t2origin=d.origin;
                offer.t2platform=d.platform;
                offer.t2price=d.price;
                offer.t2unit=d.unit;
                offer.t2currency=d.currency
            }else{
                offer.t2id=null;
                offer.t2offerid=null;
                offer.t2type=null;
                offer.t2origin=null;
                offer.t2platform=null;
                offer.t2price=null;
                offer.t2unit=null;
                offer.t2currency=null
            }

            let p = t.pkgs[i];
            if(p){
                offer.t3id=p.id;
                offer.t3offerid=p.offerId;
                offer.t3productTypeQtyMin=p.productTypeQtyMin; 
                offer.t3productTypeQtyMax=p.productTypeQtyMax; 
                offer.t3productQtyMin=p.productQtyMin; 
                offer.t3productQtyMax=p.productQtyMax; 
                offer.t3type=p.type; 
                offer.t3price=p.price; 
                offer.t3unit=p.unit; 
                offer.t3currency=p.currency;
            }else{
                offer.t3id=null;
                offer.t3offerid=null;
                offer.t3productTypeQtyMin=null; 
                offer.t3productTypeQtyMax=null; 
                offer.t3productQtyMin=null; 
                offer.t3productQtyMax=null; 
                offer.t3type=null; 
                offer.t3price=null; 
                offer.t3unit=null; 
                offer.t3currency=null;
            }

            let px = t.proxies[i];
            if(px){
                offer.t5id=px.id;
                offer.t5offerId=px.offerId;
                offer.t5proxyMin=px.proxyMin;
                offer.t5proxyMax=px.proxyMax;
                offer.t5type=px.type;
                offer.t5price=px.price;
                offer.t5unit=px.unit;
                offer.t5currency=px.currency;
            }else{
                offer.t5id=null;
                offer.t5offerId=null;
                offer.t5proxyMin=null;
                offer.t5proxyMax=null;
                offer.t5type=null;
                offer.t5price=null;
                offer.t5unit=null;
                offer.t5currency=null;
            }
            return offer;
        },

        //添加报价
        add(){
            this.curr = {
                id: null,
                userId: null,
                username: null,
                fullname: null,
                roleLabel: null,
                name: null,
                remark1: null,
                remark2: null,
                defaulted: 0,
                transits: [],
                deliveries: [],
                pkgs: [],
                pasteBill: {
                    id:null,
                    offerId: null,
                    price: 0,
                    unit: 0,
                    currency: 0
                },
                proxies: [],
            },

            this.editTitle=this.msg("新增报价设置");
            this.editVisible=true;
        },
        //编辑报价
        edit(){

            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }
            this.$axios.get(this.$kit.api.offer.detail+"?id="+selectes[0].id+"&t=0")
                .then((result) => {
                    this.$message(result.msg);
                    if(result.status){
                        
                        
                        result.data.transits.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                        });
                        result.data.deliveries.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                        });
                        result.data.pkgs.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                        });
                        result.data.proxies.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                            e.proxyMin = e.proxyMin!=0?(e.proxyMin/100.0).toFixed(2):0;
                            e.proxyMax = e.proxyMax!=0?(e.proxyMax/100.0).toFixed(2):0;
                        });
                        result.data.pasteBill.price = (result.data.pasteBill.price/100.0).toFixed(2);


                        this.curr = result.data;
                        this.editTitle = this.msg('编辑报价设置')+":"+this.curr.name;
                        this.editVisible = true;
                    }
                });
        },
        view(){

            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }

            this.$axios.get(this.$kit.api.offer.detail+"?id="+selectes[0].id+"&t=0")
                .then((result) => {
                    this.$message(result.msg);
                    if(result.status){
                        
                        
                        result.data.transits.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                        });
                        result.data.deliveries.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                        });
                        result.data.pkgs.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                        });
                        result.data.proxies.forEach(e => {
                            e.price = e.price!=0?(e.price/100.0).toFixed(2):0;
                            e.proxyMin = e.proxyMin!=0?(e.proxyMin/100.0).toFixed(2):0;
                            e.proxyMax = e.proxyMax!=0?(e.proxyMax/100.0).toFixed(2):0;
                        });
                        result.data.pasteBill.price = (result.data.pasteBill.price/100.0).toFixed(2);


                        this.curr = result.data;
                        this.viewVisible = true;
                    }
                });
        },
        //关闭更新
        closeEdit(type){
            this.editVisible=false;
            setTimeout(()=>{
                this.editVisible1 = false;
                setTimeout(()=>{
                    this.editVisible1 = true;
                }, 500);
            }, 500);

            if(type){
                this.list(true);
            }else{
                this.list();
            }
        },
        closeCopy(type){
            this.copyVisible=false;

            setTimeout(()=>{
                this.copyVisible1 = false;
                setTimeout(()=>{
                    this.copyVisible1 = true;
                }, 500);
            }, 500);

            if(type){
                this.list(true);
            }
        },
        //复制报价
        copyOf(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能复制一条数据'));
                return;
            }

            this.curr = selectes[0];

            this.copyVisible = true;

        },
        //删除报价
        deletes(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            
            var _this = this;
            this.$confirm(this.fmt('确定要删除《%s》吗?', selectes.map(e => e.name)), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.offer.deletes+"?ids="+selectes.map(e => e.id))
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.list(true);
                });
            }).catch((err) => {
                // console.log(err);
            });

        },
        // 通用行合并函数（将相同多列数据合并为一行）
        rowspanMethod ({ row, _rowIndex, column, visibleData }) {
            let fields = ['id','fullnameId', 'usernameId', 'roleLabelId', 'nameId', 'remark1Id', 'remark2Id','remark3Id', 'defaultedId']
            let cellValue = row[column.property]
            if (cellValue && fields.includes(column.property)) {
                let prevRow = visibleData[_rowIndex - 1]
                let nextRow = visibleData[_rowIndex + 1]
                if (prevRow && prevRow[column.property] === cellValue) {
                    return { rowspan: 0, colspan: 0 }
                } else {
                    let countRowspan = 1
                    while (nextRow && nextRow[column.property] === cellValue && nextRow["id"] === row['id']) {
                        nextRow = visibleData[++countRowspan + _rowIndex]
                    }
                    if (countRowspan > 1) {
                        return { rowspan: countRowspan, colspan: 1 }
                    }
                }
            }
        },
        resetFee(type){
            
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.resetType = type;

            let ids = selectes.map(e => e.id);
            let tempIds = [];
            ids.forEach(id => {
                let idx = tempIds.findIndex(e => e == id);
                if(idx == -1){
                    tempIds.push(id);
                }
            });

            this.resetIds = tempIds.join(",");
            if(type == 1){
                this.resetTitle = this.msg('运费设置');
            }else if(type==2){
                this.resetTitle = this.msg('交派费设置');
            }else if(type==3){
                this.resetTitle = this.msg('打包费设置');
            }else if(type==4){
                this.resetTitle = this.msg('贴单费设置');
            }else if(type==5){
                this.resetTitle = this.msg('代收手续费设置');
            }

            this.$axios.get(this.$kit.api.offer.detail+"?id="+selectes[0].id+"&t="+type)
                .then((result) => {
                    this.$message(result.msg);
                    if(result.status){
                        if(this.resetType == 4){
                            result.data.price = (result.data.price / 100).toFixed(2);
                        }else{
                            result.data.forEach(p => {
                                p.price = ( p.price / 100).toFixed(2);
                                if(this.resetType == 5){
                                    p.proxyMin = (p.proxyMin/100).toFixed(2);
                                    p.proxyMax = (p.proxyMax/100).toFixed(2);
                                }

                            });
                        }

                        this.fees = result.data;
                        this.resetVisible = true;
                    }
                });
        },
        updateReset(){
            
            let url = null;
            if(this.resetType == 1){
                url = this.$kit.api.offer.resetTransit;
            }else if(this.resetType == 2){
                url = this.$kit.api.offer.resetDelivery;
            }else if(this.resetType == 3){
                url = this.$kit.api.offer.resetPkg;
            }else if(this.resetType == 4){
                url = this.$kit.api.offer.resetPasteBill;
            }else if(this.resetType == 5){
                url = this.$kit.api.offer.resetProxy;
            }

            if(!url) return;

            let ps = JSON.parse(JSON.stringify(this.fees));
            if(this.resetType == 4){
                ps.price = (ps.price * 100).toFixed();
            }else{
                ps.forEach(p => {
                    p.price = ( p.price * 100).toFixed();
                    if(this.resetType == 5){
                        p.proxyMin = (p.proxyMin*100).toFixed();
                        p.proxyMax = (p.proxyMax*100).toFixed();
                    }

                });
            }

            this.$axios.post(url+"?ids="+this.resetIds, ps)
                .then((result)=>{
                    if(result.status){
                        this.$message(this.resetTitle+"设置成功");
                        if(result.status){
                            this.closeReset();
                        }
                    }else{
                        this.$message(this.resetTitle+"设置失败");
                    }
                });

        },
        editRate(){//汇率设置
            this.editRateVisible = true;
        },
        editUsed(type){
            //type=1,调用
            //type=0,取消调用
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            // let ok = true;//调用
            // if(type == 1){
            //     selectes.forEach(e => {

            //             if(e.offUsed == '使用中'){
            //                 ok = false;
            //             }
            //     });
            // }
            // if(!ok){
            //     this.$message(this.msg('所选数据含使用中的报价，请勿重复调用！'));
            //     return;
            // }

            let msg = "";
            if(type == 0){
                msg = this.msg("确定要取消调用吗？");
            }else{
                msg = this.msg("确定将选中报价设为调用状态吗？");
            }
            this.$confirm((msg), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$kit.api.offer.updateUsed, {
                    ids: selectes.map(e => e.id).join(","),
                    type: type
                }).then((result)=>{
                        this.$message(result.msg);
                        if(result.status){
                            this.list();
                        }
                    });
            }).catch((err) => {
                // console.log(err);
            });
        },
        openedRate(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.editRateDg.init(selectes.map(s=>s.id).join(","));
            });
        },
        closeRateDialog(){
            this.editRateVisible = false;
            this.list(false);
        },
        closeReset(){
            this.resetVisible=false;

            setTimeout(()=>{
                this.resetVisible1 = false;
                setTimeout(()=>{
                    this.resetVisible1 = true;
                }, 500);
            }, 500);

            this.list(true);
        },
        openRemark(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.remarkVisible = true;
        },
        updateRemark(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();

            this.$axios.post(this.$kit.api.offer.updateRemark, {
                ids: selectes.map(e => e.id).join(","),
                remark1: this.remarkForm.remark1,
                remark2: this.remarkForm.remark2
            }).then((result)=>{
                    this.$message(result.msg);
                    if(result.status){
                        if(result.status){
                            this.list();
                            this.closeRemark();
                        }
                    }
                });
        },
        closeRemark(){
            this.remarkVisible = false;
            this.remarkForm.remark1=null;
            this.remarkForm.remark2=null;
        }
    }
    
}

</script>
<style scope='scss'>
.table-wrap .vxe-header--row .vxe-header--column{
    text-align:center;
} 
</style>