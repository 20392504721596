<!--编辑交派费报价-->
<template>
    <div class="table-wrap" style="text-align:center;">
        <h4>{{msg('交派费设置')}}</h4>
        <vxe-table :data="datas" size="mini" min-height="150"
            border
            stripe
            resizable
            highlight-hover-row
        >
            <vxe-table-column type="seq" width="50" min-width="50"></vxe-table-column>
            <vxe-table-column field="origin" :title="msg('来源')" min-width="110">
                <template v-slot="{row}">
                    <el-select v-model="row.origin" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.orderOrigin" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="type" :title="msg('配送方式')" min-width="120">
                <template v-slot="{row}">
                    <el-select v-model="row.type" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.expCompany" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="platform" :title="msg('卖场')" min-width="120">
                <template v-slot="{row}">
                    <el-select v-model="row.platform" :placeholder="msg('请选择')" size="mini" clearable>
                        <el-option v-for="item in dicts.orderPlatforms" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="price" :title="msg('报价')"  width="120">
                <template v-slot="{row}">
                    <el-input type='number' v-model="row.price" size="mini" clearable></el-input>
                    <!-- <el-input-number v-model="row.price" :precision="2" :step="0.01" size="mini"></el-input-number> -->
                </template>
            </vxe-table-column>
            <vxe-table-column field="unit" :title="msg('单位')" min-width="110">
                <template v-slot="{row}">
                    <el-select v-model="row.unit" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.units" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="currency" :title="msg('币种')" min-width="110">
                <template v-slot="{row}">
                    <el-select v-model="row.currency" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.currencies" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="id" :title="msg('操作')" width="50" min-width="50">
                <template v-slot="{rowIndex }">
                    <el-link icon="el-icon-delete" type="danger" @click="datas.splice(rowIndex, 1)"></el-link>
                </template>
            </vxe-table-column>
        </vxe-table>
        <el-button style="margin-top:6px;" type="primary" icon="el-icon-plus" size="mini" @click="addOne()">{{msg('添加更多')}}</el-button>
    </div>
</template>

<script>
export default {
    name: 'OfferEditDelivery',
    props:{
        datas:Array
    },
    methods:{
        addOne(){
            this.datas.push({
                id:null,
                offerId: null,
                type: '7-11',
                origin: 0,
                platform:0,
                price: 0,
                unit: 1,
                currency: 0,
            });
        }
    } 
}
</script>

<style scoped lang="scss">


</style>
