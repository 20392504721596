<!-- 运费核算-->
<template>
<div class="content-panel">
    <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
        <el-col :span="20">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{msg('运费核算')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
    </el-row>
    <el-form ref="form" label-width="100px" style="margin-top:20px" @submit.native.prevent>
        <el-row>
            <!-- <el-col :xs="24" :sm="10">
                <el-form-item :label="msg('汇率')">
                    <el-input v-model="rate" :placeholder="msg('请输入运费汇率')" size="medium ">
                        <el-button slot="append" icon="el-icon-circle-check" @click="onSubmit">核算</el-button>
                    </el-input>
                </el-form-item>
            </el-col> -->
            <div style="margin:10px; padding:10px;">
                <span>{{msg('计算类型')}}</span>
                <el-checkbox-group v-model="selectTypes" >
                    <el-checkbox v-for="type in types" :label="type.value" :key="type.value">{{type.label}}</el-checkbox>
                </el-checkbox-group>
            </div>
            <el-col :xs="24" :sm="14">
                <div style="text-align:left;margin-top: 5px;margin-left: 20px;">
                    <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-circle-check"></i><span>{{msg('核算')}}</span></el-button>
                    <el-button type="primary" size="mini"  @click="doExport()"><i class="el-icon-download"></i><span>{{msg('导出')}}</span></el-button>
                </div>
            </el-col>
        </el-row>
    </el-form>
    <div class="table-wrap">
        <div style="margin-left:20px; font-size:14px;">{{info}}</div>
        <el-progress :percentage="speed"></el-progress>
        <vxe-table
          ref="xTable"
          border export-config
          :expand-config="{iconOpen: 'fa el-icon-remove', iconClose: 'fa el-icon-circle-plus'}"
          :data="tableData">
          <vxe-table-column type="seq" width="60"></vxe-table-column>
          <vxe-table-column type="expand" :title="msg('单号')" :formatter="formatCode" >
            <template v-slot="{ row }">
              <span>{{ row.code }}</span>
            </template>
            <template v-slot:content="{ row }">
                <div class="xdetail">
                    <h2 class="xtitle">{{msg("物流信息")}}</h2>
                    <el-row>
                        <el-col :span="8">
                            <span style="#606266">{{msg('单号')}}：</span><span style="#303133">{{row.code}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('流水号')}}：</span><span style="#303133">{{row.transitCode}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('归属人')}}：</span><span style="#303133">{{row.fullname}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('归属公司')}}：</span><span style="#303133">{{row.companyName}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('来源')}}：</span><span style="#303133">{{getLabel("orderOrigin", row.deliveryOrigin)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('配送方式')}}：</span><span style="#303133">{{getLabel("expCompany", row.deliveryCompany)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('卖场')}}：</span><span style="#303133">{{getLabel("orderPlatforms", row.deliveryPlatform)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('重量')}}：</span><span style="#303133">{{(row.weight/1000).toFixed(3)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('商品数量')}}：</span><span style="#303133">{{row.productQty}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('商品种类')}}：</span><span style="#303133">{{row.detailQty}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('出货方式')}}：</span><span style="#303133">{{getLabel("expoutType", row.expOutType)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('打包类型')}}：</span><span style="#303133">{{getLabel("stockExpPkgType", row.pkgType)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('收费方式')}}：</span><span style="#303133">{{getLabel("feeTypes", row.transitType)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('应收金额')}}：</span><span style="#303133">{{(row.amountInNeed/100).toFixed(2)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('配送类型')}}：</span><span style="#303133">{{getLabel("stockExpType", row.pkgType)}}</span>                            
                        </el-col>
                    </el-row>
                    <h2 class="xtitle">{{msg("费用汇总")}}</h2>
                    <el-row style="margin-top:10px; ">
                        <el-col :span="8">
                            <span style="#606266">{{msg('运算汇率')}}：</span><span style="#303133">{{row.rate}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('总金额')}}：</span><span style="#303133">{{(row.amount/100).toFixed(2)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('运费')}}：</span><span style="#303133">{{(row.amountTransit/100).toFixed(2)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('交派费')}}：</span><span style="#303133">{{(row.amountDelivery/100).toFixed(2)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('打包费')}}：</span><span style="#303133">{{(row.amountPkg/100).toFixed(2)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('贴单费')}}：</span><span style="#303133">{{(row.amountPasteBill/100).toFixed(2)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('附加费')}}：</span><span style="#303133">{{(row.amountOther/100).toFixed(2)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('附加费1')}}：</span><span style="#303133">
                                {{(row.amountOther1/100).toFixed(2)}}(台币)&nbsp;&nbsp;
                                {{(row.amountOther1Rmb/100).toFixed(2)}}(人民币)
                                </span>                            
                        </el-col>
                    </el-row>
                    <h2 class="xtitle">{{msg("计算标准")}}</h2>
                    <el-row style="margin-top:10px; " v-if="row.transitId">
                        <el-col :span="8">
                            <span style="#606266">{{msg('运费计重策略')}}：</span>
                            <span style="#303133">{{getLabel("weightStraties", row.transitStrategy)}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('运费报价')}}：</span>
                            <span style="#303133">
                                {{(row.transitPrice/100).toFixed(2)}}
                                {{getLabel("units", row.transitUnit)}}
                                {{getLabel("currencies", row.transitCurrenty)}}
                            </span>                            
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:10px; " v-if="row.deliveryId">
                        <el-col :span="8">
                            <span style="#606266">{{msg('交派费报价')}}：</span>
                            <span style="#303133">
                                {{(row.deliveryPrice/100).toFixed(2)}}
                                {{getLabel("units", row.deliveryUnit)}}
                                {{getLabel("currencies", row.deliveryCurrenty)}}
                            </span>                            
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:10px;" v-if="row.pkgId">
                        <el-col :span="8">
                            <span style="#606266">{{msg('打包费商品种类数量')}}：</span>
                            <span style="#303133">{{row.productTypeQtyMin}}-{{row.productTypeQtyMax}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('打包费商品总数量')}}：</span>
                            <span style="#303133">{{row.productQtyMin}}-{{row.productQtyMax}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('打包费报价')}}：</span>
                            <span style="#303133">
                                {{(row.pkgPrice/100).toFixed(2)}}
                                {{getLabel("units", row.pkgUnit)}}
                                {{getLabel("currencies", row.pkgCurrency)}}
                            </span>                            
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:10px; " v-if="row.pkgId">
                        <el-col :span="8">
                            <span style="#606266">{{msg('贴单费报价')}}：</span>
                            <span style="#303133">
                                {{(row.pasteBillPrice/100).toFixed(2)}}
                                {{getLabel("units", row.pasteBillUnit)}}
                                {{getLabel("currencies", row.pasteBillCurrency)}}
                            </span>                            
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:10px;" v-if="row.proxyId">
                        <el-col :span="8">
                            <span style="#606266">{{msg('代收标准')}}：</span>
                            <span style="#303133">{{row.proxyMin}}-{{row.proxyMax}}</span>                            
                        </el-col>
                        <el-col :span="8">
                            <span style="#606266">{{msg('手续费报价')}}：</span>
                            <span style="#303133">
                                {{(row.proxyPrice/100).toFixed(2)}}
                                {{getLabel("units", row.proxyUnit)}}
                                {{getLabel("currencies", row.proxyCurrency)}}
                            </span>                            
                        </el-col>
                    </el-row>
                </div>
            </template>
            </vxe-table-column>
            <vxe-table-column field="amount" :title="msg('总费用')" :formatter="({ cellValue }) => (cellValue/100.0).toFixed(2)" sortable>
                <template v-slot="{ row }">
                    {{(row.amount/100.0).toFixed(2)}}
                </template>
            </vxe-table-column>
            <vxe-table-column field="msg" :title="msg('计算结果')"
                :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
            ></vxe-table-column>
        </vxe-table>
    </div>
</div>
</template>

<script>
import axios from "axios";
export default {
    name: "StockDetailEdit",
    components: {
    },
    mounted(){
        this.init();
    },
    data(){
        return {
            tableData: [
            ],
            ids:null,
            rate:0,
            speed:0,
            timers:[],
            len:0,
            info: null,
            selectTypes: [1,2,3,4,5,6,7],
            types: [
                {value: 1, label: this.msg('运费核算-运费')},
                {value: 2, label: this.msg('运费核算-交派费')},
                {value: 3, label: this.msg('运费核算-打包费')},
                {value: 4, label: this.msg('运费核算-贴单费')},
                {value: 5, label: this.msg('运费核算-代收手续费')},
                {value: 6, label: this.msg('运费核算-附加费（人民币）')},
                {value: 7, label: this.msg('运费核算-附加费1（台币）')},
            ],

        }
    },
    computed: {
        isMinScreen: { //是否小屏幕，浏览器
            get() {
                return this.$store.state.isMinScreen;
            }
        },
        docHeight: { //表格的高度
            get() {
                var height = this.$store.state.docHeight;
                return height - 165;
            }
        }
    },
    methods:{
        init(){
            this.ids = window.localStorage.getItem("stockIds1");
            this.len = this.$kit.getQueryValue("len");
            // this.ids = this.$kit.getQueryValue("ids");
            // // this.getData();
            // console.log(this.len,this.ids);
        },
        getData(){
            let ts = -1;
            if(this.selectTypes.length==0){
                this.$message(this.msg('至少计算一种费用'));
                return;
            }

            ts = this.selectTypes.join(",");

            let param = {
                ids:this.ids,
                ts: ts
                // rate: this.rate
            }
            this.$axios.post(this.$kit.api.finance.computeTransit,param)
                .then((result) => {
                    this.$message(result.msg);
                    if(result.status){
                        this.speed = 0;
                        this.tableData = [];
                        this.info = this.msg('计算中');
                        this.starTimer();
                    }
                });
        },
        starTimer(){
            let t = setInterval(()=>{
                if(this.speed < 100){
                    this.getPercent();
                }else{
                    this.finish();
                    for(let i = 0; i<this.timers.length; i++){
                        clearInterval(this.timers[i]);
                    }
                    this.timers = [];
                }
            } ,1000);
            this.timers.push(t);
        },
        onSubmit(){
            // if(!this.rate){
            //     this.$message(this.msg('请输入汇率'));
            //     return;
            // }
            this.getData();
        },
        getPercent(){
            axios.request({
                url: this.$kit.api.finance.transitPercent, 
                method: 'get',
                headers: {"Content-Type":"application/json; charset=utf-8",
                        "X-Requested-With":"XMLHttpRequest",
                    },
                withCredentials: true,
            }).then((response) => {
                    let result = response.data;
                    if(result.status){

                        if(result.data && result.data.length > 0){
                            this.tableData = result.data;
                            this.speed = (this.tableData.length/this.len)*100;
                            this.info = this.msg('当前进度')+this.tableData.length+"/"+this.len;
                            // console.log(this.tableData.length, this.len, this.speed);
                        }

                    }
                });
        },
        finish(){
            axios.request({
                url: this.$kit.api.finance.transitComputeFinish, 
                method: 'get',
                headers: {"Content-Type":"application/json; charset=utf-8",
                        "X-Requested-With":"XMLHttpRequest",
                    },
                withCredentials: true,
            })
        },
        getLabel(key, value){
            if(value == null) return "";
            let data = this.dicts[key].find(e => e.value == value);
            return data&&data.label? data.label : value;
        },
        doExport(){
            this.$refs.xTable.openExport({ isPrint: false })
        },
        formatterAmount({ cellValue }){
            return (cellValue/100.0).toFixed(2)
        },
        formatCode(data){
            return data.row.code;
        }
    },
    destroyed(){

        if(this.timers && this.timers.length>0){
            for(let i=0; i<this.timers.length; i++){
                clearInterval(this.timers[i]);
            }
        }
    }
    
}
</script>

<style lang="scss" scoped>

.table-wrap{
    width:100%;
    overflow:auto;
}
.xtitle{font-size:16px;}
</style>
