<!--编辑运费报价-->
<template>
    <div class="table-wrap" style="text-align:center;">
        <h4>{{msg('运费设置')}}</h4>
        <vxe-table :data="datas" size="mini" min-height="150"
            border
            stripe
            resizable
            highlight-hover-row
        >
            <vxe-table-column type="seq" width="50" min-width="50"></vxe-table-column>
            <vxe-table-column field="type" :title="msg('收费方式')" min-width="120">
                <template v-slot="{row}">
                    <el-select v-model="row.type" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.feeTypes" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="strategy" :title="msg('计重策略')" min-width="120">
                <template v-slot="{row}">
                    <el-select v-model="row.strategy" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.weightStraties" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="price" :title="msg('报价')"  width="120" >
                <template v-slot="{row}">
                    <el-input type='number' v-model="row.price" size="mini" clearable></el-input>
                </template>
            </vxe-table-column>
            <vxe-table-column field="unit" :title="msg('单位')" min-width="120">
                <template v-slot="{row}">
                    <el-select v-model="row.unit" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.units" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="currency" :title="msg('币种')" min-width="120">
                <template v-slot="{row}">
                    <el-select v-model="row.currency" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.currencies" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="defaultted" :title="msg('默认')" min-width="120">
                <template v-slot="{row}">
                    <el-select v-model="row.defaulted" :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="item in dicts.defaulteds" :key="item.value" 
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </vxe-table-column>
            <vxe-table-column field="id" :title="msg('操作')" width="50" min-width="50">
                <template v-slot="{row, rowIndex }">
                    <el-link icon="el-icon-delete" type="danger" @click="datas.splice(rowIndex, 1)"></el-link>
                </template>
            </vxe-table-column>
        </vxe-table>
        <el-button style="margin-top:6px;" type="primary" icon="el-icon-plus" size="mini" @click="addOne()">{{msg('添加更多')}}</el-button>
    </div>
</template>

<script>
export default {
    name: 'OfferEditTransit',
    props:{
        datas:Array
    },
    methods:{
        addOne(){
            this.datas.push({
                id:null,
                offerId: null,
                type: 0,
                strategy: 0,
                price: 0,
                unit: 0,
                currency: 0,
                defaulted: 0
            });
        }
    } 
}
</script>

<style scoped lang="scss">


</style>
