<!--复制报价设置-->
<template>
<el-form ref="form" @submit.native.prevent :model="offer" label-width="80px" size="mini" >
    
    <div class="tags" style="padding:10px;">
        <el-tag type="danger" style="margin-right:5px; margin-bottom:5px;" v-for="(user, idx) in users" :key="user.id" closable @close="() => users.splice(idx, 1)">{{user.label}}</el-tag>
    </div>

    <el-form-item :label="msg('客户名')">
        <el-autocomplete style="width:500px;"
            v-model="offer.text"
            :fetch-suggestions="searchUser"
            @clear="clearUsername"
            :placeholder="msg('请输入账号或者姓名')"
            @select="selectUser"
        ></el-autocomplete>
    </el-form-item>

    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>

export default {
    name: 'OfferEdit',
    props:{
        offerId:Number,
    },
    data(){
        return {
            users: [],
            offer: {
                text:null
            },
        }
    },
    methods:{
        searchUser(queryString, cbFn){  //搜索用户
            this.$axios.get(this.$kit.api.user.search+"?name="+encodeURIComponent(queryString)+"&type=1")
                .then((result) => {
                    if(result.status){
                        let users =[];
                        result.data.forEach(e => {
                            users.push({
                                id: e.id,
                                value: e.value,
                            });
                        });
                        cbFn(users);
                    }
                });
        },
        //清除选中的用户
        clearUsername(){
            this.offer.text = null;
        },
        //选中用户
        selectUser(item) { //获取选中的用户
            let idx = this.users.findIndex(u => u.id == item.id);
            if(idx == -1){
                this.users.push({
                    id: item.id,
                    label: item.value
                });
            }
            this.offer.text = null;
        },
        //提交
        onSubmit(){
            if(this.users.lengt==0){
                this.$message(this.msg("请选择用户"));
                return ;
            }
            this.$axios.get(this.$kit.api.offer.copyOf+"?offerId="+this.offerId+"&ids="+this.users.map(u => u.id).join(","))
                .then((result)=>{
                    if(result.data){
                        this.$message({
                            dangerouslyUseHTMLString: true,
                            message: result.data ? result.data.join("<br/>") : result.msg
                        });
                    }else{
                        this.$message(result.msg);
                    }
                });
        }
  }
}
</script>

<style scoped lang="scss">


</style>
